import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import niufeld from '../images/niu_feld.jpg';
import niugarage from '../images/niu_garage.jpg';
import niuimage from '../images/niu_nergie.jpg';


const Home = () => (
  <div>
    <h2>Willkommen auf der Seite Alexander-Business-Solutions</h2>

    <div className="container-fluid">
    <div className="row">


  <div className="col-sm-12 col-lg-4">
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">Verkauf von Niu Domains</h5>
        <p className="card-text">
        <div className="alert alert-primary" role="alert">
        Für Händler und Enthusiasten. Sichere dir eine schöne Niu Domain.
</div>
        <div className="alert alert-dark" role="alert">Informieren Sie sich über meinen aktuellen <Link to="/domainsale">Domain-Verkauf</Link></div>
        </p>
      </div>
    </div>

    <div className="card">
      <div className="card-body">
        <h5 className="card-title">Weitere Aktivitäten von ABS</h5>
        <p className="card-text">
        <div className="alert alert-success" role="alert">
        Aufträge für Web-Projekte (mit .net core, razor oder reactjs) und Android/iOS Apps (mit Flutter) bitte direkt an <a href="mailto:info@alexander-business-solutions.de">info@ABS</a>
        </div>
        <div className="alert alert-dark" role="alert">
        Bald werden hier die ersten Web-Projekte 2020 meiner Kunden-Aufträge vorgestellt.
        </div>
        <div className="alert alert-danger" role="alert">Hoffentlich finde ich bald für meine eigene (diese) Seite so viel Zeit wie für meine Auftragsarbeiten. ;)</div>
        </p>
      </div>
    </div>
</div>

      
    <div className="col-sm-12 col-lg-4">
    <div className="card">
      <img src={niufeld} className="card-img-top" alt="..." />
      <div className="card-body">
        <h5 className="card-title"></h5>
        <p className="card-text">Klimaneutral in die Zukunft mit Niu. Blog mit Niu-Artikeln befindet sich noch im Aufbau..</p>
      </div>
    </div>

      <div className="card">
      <img src={niugarage} className="card-img-top" alt="..." />
      <div className="card-body">
        <h5 className="card-title">Nius sind nie allein.</h5>
        <p className="card-text">und passen sogar zu Zweit gemeinsam mit dem Auto auf einen Pkw Parkplatz.</p>
      </div>
    </div>
  </div>

    <div className="col-sm-12 col-lg-4">
      <div className="card">
      <img src={niuimage} className="card-img-top" alt="..." />
      <div className="card-body">
        <h5 className="card-title">Niu immer weiter fahren..</h5>
        <p className="card-text">    <div className="alert alert-success" role="alert">
      Niu innerhalb von 60 Minuten um bis zu 50% aufladen. Umbau Anfragen auf Schnellladetechnik bitte direkt an <a href="mailto:niu@alexander-business-solutions.de">Niu@ABS</a>
  </div></p>
      </div>
    </div>
    </div>

  </div>
  </div>


  

  </div>
);

export default connect()(Home);
