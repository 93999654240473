import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { ApplicationState } from '../store';
import * as WeatherForecastsStore from '../store/WeatherForecasts';

// At runtime, Redux will merge together...
type WeatherForecastProps =
  WeatherForecastsStore.WeatherForecastsState // ... state we've requested from the Redux store
  & typeof WeatherForecastsStore.actionCreators // ... plus action creators we've requested
  & RouteComponentProps<{ startDateIndex: string }>; // ... plus incoming routing parameters


class FetchData extends React.PureComponent<WeatherForecastProps> {
  // This method is called when the component is first added to the document
  public componentDidMount() {
    this.ensureDataFetched();
  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    this.ensureDataFetched();
  }

  public render() {
    return (
      <React.Fragment>
        <h1 id="tabelLabel">Domainverkauf</h1>
        <p>Hier findet sich eine Auflistung aller momentan verfügbaren Domains.</p>
        <p>Alternativ zu Sedo ist die Erst-Kontaktaufnahme per <a className="mail" href="mailto:domainsale@alexander-business-solutions.de">E-Mail</a> möglich. Alles weitere erfolgt daraufhin nach Ihrem Wunsch schriftlich oder per Telefon.</p>
        {this.renderDomainTable()}
        {this.renderPagination()}
      </React.Fragment>
    );
  }

  private ensureDataFetched() {
    const startDateIndex = (this.props.match.params.startDateIndex, 10) || 0;
    this.props.requestWeatherForecasts(startDateIndex);
  }

  private renderDomainTable() {
    return (
      <table className='table table-striped' aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>Domain</th>
            <th>Beschreibung</th>
            <th>Link</th>
          </tr>
        </thead>
        <tbody>
          {this.props.forecasts.map((forecast: WeatherForecastsStore.DomainInfo) =>
            <tr key={forecast.url}>
              <td><a target="_blank" href={"https://"+forecast.url}>{forecast.url}</a></td>
              <td>{forecast.description}</td>
              <td><a target="_blank" href={forecast.sedo}>Sedo Verkaufslink</a></td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  private renderPagination() {
    const prevStartDateIndex = (this.props.startDateIndex || 0) - 5;
    const nextStartDateIndex = (this.props.startDateIndex || 0) + 5;

    return (
      <div className="d-flex justify-content-between">
        <Link className='btn btn-outline-secondary btn-sm' to={`/domainsale/${prevStartDateIndex}`}>Zurück</Link>
        {this.props.isLoading && <span>Lädt...</span>}
        <Link className='btn btn-outline-secondary btn-sm' to={`/domainsale/${nextStartDateIndex}`}>Vorwärts</Link>
      </div>
    );
  }
}

export default connect(
  (state: ApplicationState) => state.weatherForecasts, // Selects which state properties are merged into the component's props
  WeatherForecastsStore.actionCreators // Selects which action creators are merged into the component's props
)(FetchData as any);
